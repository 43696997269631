'use strict';

const DAY = 2592000000; // 30 days;
const quantityAdultsCookieName = 'bookingFormQuantityAdults'; // uses in different places
const quantityChildCookieName = 'bookingFormQuantityChild'; // uses in different places
const lifeTimeCookie = new Date(new Date().getTime() + 2 * DAY).toUTCString(); // set cookie on 60 days;

let selectedLocationLink = '';

// get data from $locationsMenu;
function createLocationsArray(obj, allowSearchByCountry, entity, allowedLocations, purpose = '') {
    let destinationsList = [];
    const checkByAllowedLocations = allowedLocations.length;

    Object.keys(obj).forEach(key => {
        const item = obj[key];

        if (allowSearchByCountry) {
            if (!checkByAllowedLocations || isAllowedLocation(item.countrySlug, allowedLocations)) {
                destinationsList.push({
                    name: key,
                    url: item.countryUrl,
                    countryUrl: item.countrySlug
                });
            }
        }

        if (item.cities && typeof item.cities === 'object') {
            Object.values(item.cities).forEach(city => {
                if (!checkByAllowedLocations || isAllowedLocation(city.location_url, allowedLocations)) {
                    const locationData = {
                        name: `${city.location}, ${key}`,
                        locationUrl: city.location_url,
                        locationId: city.id,
                        countrySlug: item.countrySlug
                    };

                    if (entity === 'experience') {
                        locationData.url = city.destinationUrl;
                    } else if (entity === 'host') {
                        locationData.url = city.hostUrl;
                    }

                    destinationsList.push(locationData);
                }
            });
        }
    });

    // Sort alphabetically by city name
    if (destinationsList.length) {
        destinationsList.sort((a, b) => {
            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });
    }

    return destinationsList;
}

function isAllowedLocation(locationUrl, allowedLocations) {
    return allowedLocations.includes(locationUrl);
}

// get data from $popularDestinations;
function createPopulationArray(popularDestinations) {
    return  popularDestinations.map(item => ({
        name: `${item.location}, ${item.country}`,
        url: item.destinationUrl
    }));
}

const SearchTool = function(selector) {
    this.selector = typeof selector !== 'undefined' && selector.length ? selector : '.js-search-tool';
    this.isKeyDownInited = false;
    this.popularList = [];

    let searchTool = $(this.selector);
    let searchInput = searchTool.find('input[name="search-tool"]');
    let peopleArea = searchTool.find('.people-area');
    let searchResults = searchTool.find('.search-results');
    let searchResultsList = searchTool.find('.search-results__list');
    let buttonSearchArea = searchTool.find('.button-search-area');
    let adultsBlock = searchTool.find('.adults');
    let childrenBlock = searchTool.find('.children');
    let entity = $(this.selector).data('entity');
    let purpose = $(this.selector).data('purpose');
    const topPlaceholder = searchTool.find('.top-placeholder');
    const bottomPlaceholder = searchTool.find('.bottom-placeholder');

    if (!entity.length) {
        entity = 'experience';
    }

    let self = this;

    const maxAdults = parseInt(adultsBlock.data('max'), 10);
    const minAdults = parseInt(adultsBlock.data('min'), 10);
    const defaultAdultsValueCookie = typeof adultsBlock.data('default') !== 'undefined' && adultsBlock.data('default')
        ? parseInt(adultsBlock.data('default'), 10) : 2; //2 should be equal with a default value in BE

    const maxChildren = parseInt(childrenBlock.data('max'), 10);
    const minChildren = parseInt(childrenBlock.data('min'), 10);
    const defaultChildrenValueCookie = typeof childrenBlock.data('default') !== 'undefined' && childrenBlock.data('default')
        ? parseInt(childrenBlock.data('default'), 10) : 0;

    let locationList;

    let allowsearchbycountry = false;
    if (searchTool.data('allowsearchbycountry')) {
        allowsearchbycountry = true;
    }

    const init = function() {
        self.init();
    };

    this.init = function() {
        const allowedLocations = typeof allowedLocationsByLocationUrl !== 'undefined' ? allowedLocationsByLocationUrl : [];

        locationList = createLocationsArray(locationsMenu, allowsearchbycountry, entity, allowedLocations, purpose);

        if (typeof popularDestinations !== 'undefined' && popularDestinations.length > 0) {
            this.popularList = createPopulationArray(popularDestinations);
        }
        searchInput.removeAttr('disabled');

        searchInput.on('click', () => {
            searchResults.addClass('show');
        });

        searchInput.on('input', function() {
            let val = $(this).val().replace(/,| /g, '');

            searchResultsList.children().remove();

            if (val.length >= 3) {
                let matchArray = [];
                const locationUSA = 'United States';
                searchInput.addClass('opened-search-results');

                if (locationUSA.toLowerCase().indexOf($(this).val().toLowerCase()) > -1 ) {
                    matchArray.push(
                        { name: 'New York, USA', url: '/usa/new-york-tours' },
                        { name: 'San Francisco, USA', url: '/usa/san-francisco-tours' },
                        { name: 'Seattle, USA', url: '/usa/seattle-tours' }
                    );
                }

                for (let item of locationList) {
                    let result = item.name.toLowerCase().indexOf($(this).val().toLowerCase());

                    if (result > -1) {
                        matchArray.push(item);
                    }
                }

                matchArray.length !== 0
                    ? showSearchLocation(matchArray)
                    : showSearchLocation(this.popularList);

                navigateOnSearchList();
            }

            if (!val.length && purpose && purpose === 'filter' && typeof runFilteringExternal === 'function') {
                $('.js-search-tool[data-purpose="filter"]').find('input[name="search-tool"]').attr('data-locationid', '').attr('data-country', '');
                runFilteringExternal();
            }

            togglePlaceholders();
        });

        searchInput.on('focus', togglePlaceholders);
        searchInput.on('blur', togglePlaceholders);
        
        togglePlaceholders();

        initPredefinedInputValue();

        document.addEventListener('click', clickDocumentToCloseHeroMenu);
        startIncrementorsSearchTool();
        clickButtonSearch();

        if (peopleArea.length) {
            peopleArea.on('click', function (event) {
                event.stopImmediatePropagation();
                !$(event.target).closest('.people-area-dropdown').length && $(this).find('.people-area-dropdown').toggleClass('no-display');
            });

            document.addEventListener('click', event => {
                    if(!$(event.target).closest('.people-area').length) {
                        peopleArea.find('.people-area-dropdown').addClass('no-display');
                    }
                }
            );

            if ($('.js-booking-form').length) {
                $('#f_quantity1').val(parseInt(peopleArea.find('.adults .js-quantity-search-tool').data('quantity'), 10));
                $('#f_quantity2').val(parseInt(peopleArea.find('.children .js-quantity-search-tool').data('quantity'), 10));
            }
        }
    };

    const initPredefinedInputValue = function () {
        let leaveEmpty = true;

        if (searchInput.attr('data-currenturl') || searchInput.attr('data-locationid') || searchInput.attr('data-country')) {
            for (let item of locationList) {
                if (
                    (searchInput.attr('data-currenturl') && item.url === searchInput.attr('data-currenturl')) ||
                    (searchInput.attr('data-locationid') && typeof item.locationId !== 'undefined' && item.locationId === parseInt(searchInput.attr('data-locationid'), 10)) ||
                    (searchInput.attr('data-country') && item.countryUrl === searchInput.attr('data-country'))
                ) {
                    searchInput.val(item.name);
                    selectedLocationLink = item.url;
                    leaveEmpty = false;
                }
            }
        }

        if (leaveEmpty) {
            searchInput.val('');
        }
    };

    const clickDocumentToCloseHeroMenu = function(event) {
        const heroMain = searchInput.length && $(event.target).is(searchInput);
        const isActiveMenu = searchResults && ($(event.target).is(searchResults) || searchResults.hasClass('show'));
        !heroMain && isActiveMenu ? closeSearchResult() : null;
    };

    let showSearchLocation = function(arr) {
        if (arr !== undefined && arr.length > 0) {
            for (let match of arr) {
                let listItem = document.createElement('li');
                listItem.className = 'search-results__list-item';
                let listItemLink = document.createElement('a');
                listItemLink.href = match.url;
                listItemLink.tabIndex = 0;
                listItemLink.innerText = match.name;
                listItemLink.onclick = e => {
                    e.preventDefault();
                    searchInput.val(e.target.innerText);
                    selectedLocationLink = listItemLink.href;

                    searchInput.attr('data-country', '').attr('data-location', '').attr('data-locationid', '');

                    if (typeof match.countryUrl !== 'undefined') {
                        searchInput.attr('data-country', match.countryUrl);
                    }

                    if (typeof match.locationUrl !== 'undefined') {
                        searchInput.attr('data-location', match.locationUrl);
                    }

                    if (typeof match.locationId !== 'undefined') {
                        $('.js-search-tool[data-purpose="filter"]').find('input[name="search-tool"]').each(function () {
                            $(this).attr('data-locationid', match.locationId).attr('data-country', match.countryUrl);
                        });
                    }

                    if (purpose && purpose === 'filter' && typeof runFilteringExternal === 'function') {
                        runFilteringExternal();
                        $('.js-search-tool[data-purpose="filter"]').find('input[name="search-tool"]').each(function () {
                            runFilteringExternal();
                        });
                    } else {
                        if (!buttonSearchArea.length) {
                            applySearch();
                        }
                    }
                };
                listItem.appendChild(listItemLink);
                searchResultsList.append(listItem);
            }   
        } else {
            searchResultsList.append('<div class="not-found">Not found</div>');
        }
    };

    // navigate on search list with keyboard;
    let navigateOnSearchList = function() {
        if (searchResultsList.children().length !== 0 && !self.isKeyDownInited) {
            self.isKeyDownInited = true;

            searchInput.on('keydown', function(e) {

                if (e.keyCode === 38) { // key up pressed

                    if (!searchResultsList.children().hasClass('selected')) {
                        searchResultsList.find('li:last').addClass('selected');
                    }

                    else {
                        let selectedItem = $('.selected');

                        if (typeof selectedItem.prev()[0] !== 'undefined') {
                            selectedItem.prev().addClass('selected');
                            selectedItem.removeClass('selected');
                        }
                    }

                } else if (e.keyCode === 40) { // key down pressed

                    if (!searchResultsList.children().hasClass('selected')) {
                        searchResultsList.find('li:first').addClass('selected');
                    }

                    else {
                        let selectedItem = $('.selected');

                        if (typeof selectedItem.next()[0] !== 'undefined') {
                            selectedItem.next('li').addClass('selected');
                            selectedItem.removeClass('selected');
                        }
                    }
                }

                if (e.keyCode === 13) { // key enter pressed

                    if ($('.selected').length) {

                        searchInput.val($('.selected').text());

                        selectedLocationLink = $('.selected').children('a').attr('href');

                        searchInput.removeClass('opened-search-results');
                        searchResultsList.children().remove();
                        applySearch();
                    }
                }
            });
        }
    };

    const closeSearchResult = function() {
        searchInput.removeClass('opened-search-results');
        searchResultsList.children().remove();
        $('.intro__home-page__wrapper').css({ 'pointer-events': 'initial' });
        if (purpose && purpose === 'filter' && isMobile) {
            $('.js-search-tool[data-purpose="filter"]').removeClass('search-tool-filter');
        }
    };

    function setDataToCookie(name, value, timeCookie, callback) {
        timeCookie = timeCookie || lifeTimeCookie;

        document.cookie = name + '=' + value + '; expires =' + timeCookie + ';path=/;SameSite=None; Secure;';

        if (typeof callback === 'function') {
            callback();
        }
    }

    const clickButtonSearch = function() {
        buttonSearchArea.on('click', () => {
            applySearch();
        });
    };

    const applySearch = function() {
        selectedLocationLink ? window.location.href = selectedLocationLink : searchInput.focus();
    };

    const startIncrementorsSearchTool = () => {
        const maxAllPeople = 8;
        const adultsCookieName = typeof adultsBlock.data('cookiename') !== 'undefined' ? adultsBlock.data('cookiename') : quantityAdultsCookieName;
        let initAdultsPeopleQuantity = parseInt(getCookie(adultsCookieName), 10) ? parseInt(getCookie(adultsCookieName), 10) : defaultAdultsValueCookie;
        let initChildrenPeopleQuantity = parseInt(getCookie(childrenBlock.data('cookiename')), 10) ? parseInt(getCookie(childrenBlock.data('cookiename')), 10) : defaultChildrenValueCookie;

        function checkPeopleQuantity (adultsValue, childrenValue) {
            let allPeople = adultsValue + childrenValue;
    
            if (allPeople > maxAllPeople) {
                peopleArea.find('.button-plus').attr('disabled', 'disabled');
                childrenValue === (minChildren + 1) && childrenBlock.find('.button-minus').attr('disabled', 'disabled');
                adultsValue === (minAdults + 1) && adultsBlock.find('.button-minus').attr('disabled', 'disabled');
            } 
            adultsValue === minAdults && adultsBlock.find('.button-minus').attr('disabled', 'disabled');
            childrenValue === minChildren && childrenBlock.find('.button-minus').attr('disabled', 'disabled');
        }

        if (initAdultsPeopleQuantity > maxAdults) {
            initAdultsPeopleQuantity = maxAdults;
        }

        if (initChildrenPeopleQuantity > maxChildren) {
            initChildrenPeopleQuantity = maxChildren;
        }

        adultsBlock.find('.js-quantity-search-tool').data('quantity', initAdultsPeopleQuantity);
        childrenBlock.find('.js-quantity-search-tool').data('quantity', initChildrenPeopleQuantity);

        updatePeopleAreaText();
        updatePricesBySelectedQuantity(initAdultsPeopleQuantity, initChildrenPeopleQuantity);
        checkPeopleQuantity(initAdultsPeopleQuantity, initChildrenPeopleQuantity);

        peopleArea.find('.js-incrementor-button').on('click', function() {
            const currentBlock = $(this).closest('.people-item');
            peopleArea.find('.js-incrementor-button').removeAttr('disabled');

            let quantitySearchToolValue = parseInt(currentBlock.find('.js-quantity-search-tool').data('quantity'), 10);

            if (!quantitySearchToolValue) {
                quantitySearchToolValue = parseInt(currentBlock.data('default'), 10);
            }

            let newVal = quantitySearchToolValue + parseInt($(this).data('number'), 10);

            let currentAdultsValue, currentChildrenValue;
            if (currentBlock.hasClass('adults')) {
                currentAdultsValue = newVal;
                currentChildrenValue = parseInt(currentBlock.parent().find('.children .js-quantity-search-tool').data('quantity'), 10);
            } else {
                currentChildrenValue = newVal;
                currentAdultsValue = parseInt(currentBlock.parent().find('.adults .js-quantity-search-tool').data('quantity'), 10);
            }

            checkPeopleQuantity(currentAdultsValue, currentChildrenValue);

            if (currentAdultsValue + currentChildrenValue > maxAllPeople) {
                newVal--;
                showPopupMessage(`<p style="text-align: center;">The maximum number of people cannot exceed ${maxAllPeople} people</p>`);
            }

            currentBlock.find('.js-quantity-search-tool').data('quantity', newVal);

            updatePeopleAreaText();

            setDataToCookie(currentBlock.data('cookiename'), newVal, lifeTimeCookie);
            if ($('.js-booking-form').length) {
                if (currentBlock.hasClass('adults')) {
                    $('#f_quantity1').val(newVal);
                } else if (currentBlock.hasClass('children')) {
                    $('#f_quantity2').val(newVal);
                }

                if (typeof updatePrice === 'function') {
                    updatePrice();
                }
            }

            syncAllSearchToolsWithCurrent(searchTool);

            if (currentBlock.hasClass('adults') || currentBlock.hasClass('children')) {
                updatePricesBySelectedQuantity(currentAdultsValue, currentChildrenValue);
            }
        });
    };

    const syncAllSearchToolsWithCurrent = (currentSearchTool) => {
        const adults = parseInt(currentSearchTool.find('.adults .js-quantity-search-tool').data('quantity'), 10);
        const children = parseInt(currentSearchTool.find('.children .js-quantity-search-tool').data('quantity'), 10);

        let searchTools = $('.js-search-tool');
        if (searchTools.length > 1) {
            searchTools.each(function () {
                const searchToolAdults = parseInt($(this).find('.adults .js-quantity-search-tool').data('quantity'), 10);
                const searchToolChildren = parseInt($(this).find('.children .js-quantity-search-tool').data('quantity'), 10);

                if (!isNaN(searchToolAdults) && !isNaN(searchToolChildren)) {
                    if (searchToolAdults !== adults) {
                        if (searchToolAdults < adults) {
                            $(this).find('.adults').find('.button-plus').trigger('click');
                        } else if (searchToolAdults > adults) {
                            $(this).find('.adults').find('.button-minus').trigger('click');
                        }
                    }

                    if (searchToolChildren !== children) {
                        if (searchToolChildren < children) {
                            $(this).find('.children').find('.button-plus').trigger('click');
                        } else if (searchToolChildren > children) {
                            $(this).find('.children').find('.button-minus').trigger('click');
                        }
                    }
                }
            });
        }
    };

    const updatePeopleAreaText = () => {
        const adultsPeopleQuantity = parseInt(adultsBlock.find('.js-quantity-search-tool').data('quantity'), 10);
        const childrenPeopleQuantity = parseInt(childrenBlock.find('.js-quantity-search-tool').data('quantity'), 10);
        const gusestsText = adultsPeopleQuantity + childrenPeopleQuantity + ' guests';

        const adultsBlockText = adultsPeopleQuantity;
        adultsBlock.find('.js-quantity-search-tool').text(adultsBlockText);
        childrenBlock.find('.js-quantity-search-tool').text(childrenPeopleQuantity);

        peopleArea.find('.people-area-text').text(gusestsText);
    };

    const updatePricesBySelectedQuantity = (qttyAdults, qttyChildren) => {
        $('.js-price-changing').each(function () {
            const card = $(this);
            const isVirtual = card.attr('data-isvirtual');

            if (typeof isVirtual === 'undefined' || !parseInt(isVirtual, 10)) {
                updateExperienceCardPrice(card, qttyAdults, qttyChildren);
            }
        });
    };

    const togglePlaceholders = () => {
        if (searchInput.val()) {
            topPlaceholder.addClass('no-display');
            bottomPlaceholder.addClass('no-display');
        } else {
            topPlaceholder.removeClass('no-display');
            bottomPlaceholder.removeClass('no-display');
        }
    };
};

$('.js-search-tool').each(function () {
    if ($(this).attr('id')) {
        new SelectorInitiator().setObject(new SearchTool('#' + $(this).attr('id')));
    } else {
        new SelectorInitiator().setObject(new SearchTool());
    }
});