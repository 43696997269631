function initReviewsCard(container) {
    function checkReviewText(reviewText, showMoreBtn) {
        if (!reviewText || !showMoreBtn) return;

        const style = window.getComputedStyle(reviewText);
        const lineHeight = parseInt(style.lineHeight);
        const adjustedHeight = reviewText.scrollHeight - parseInt(style.paddingTop) - parseInt(style.paddingBottom) - parseInt(style.borderTopWidth) - parseInt(style.borderBottomWidth);
        
        showMoreBtn.classList.toggle('no-display', Math.ceil(adjustedHeight / lineHeight) <= 4);
    }

    function toggleReviewText(event) {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
        const reviewText = event.target.previousElementSibling;
        const isExpanded = reviewText.classList.toggle('full');
        event.target.textContent = isExpanded ? 'Hide' : 'Show more';
    }

    function updateReviews() {
        container.querySelectorAll('.card-reviews').forEach(item => {
            checkReviewText(item.querySelector('.reviews-content'), item.querySelector('.btn-show-more'));
        });
    }

    container.querySelectorAll('.btn-show-more').forEach(button => {
        button.addEventListener('click', toggleReviewText);
    });

    window.addEventListener('resize', debounce(updateReviews, 300));
    updateReviews();
}