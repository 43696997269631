"use strict";

const HomePage = function () {
    this.selector = ".js-home-page";
    const self = document.querySelector(this.selector);
    const navbar = document.querySelector(".main-nav");
    const searchForm = self.querySelector(".search-section-container");
    const searchInput = searchForm.querySelector("input");
    const placeholders = self.querySelectorAll(".js-search-placeholder");

    this.init = function () {
        initSearchForm();
        initAnimations();

        if (document.querySelector(".js-video-section")) {
            initVideo(document.querySelector(".js-video-section"));
        }
        if (document.querySelector(".js-hero-video-section")) {
            initVideo(document.querySelector(".js-hero-video-section"));
        }
        if (self.querySelector('.js-start-planning-btn')) {
            self.querySelectorAll('.js-start-planning-btn').forEach(button => {
                button.addEventListener('click', function() {
                    searchInput.focus();
                    searchForm.classList.add('scale-animation');
                    setTimeout(() => {
                        searchForm.classList.remove('scale-animation');
                    }, 2000);
                });
            })
        }
        
        new Swiper('.as-seen-in-slider', {
            loop: true,
            centeredSlides: true,
            autoplay: {
                delay: 1000,
                disableOnInteraction: false,
            },
        });

        new Swiper('.customer-stories-slider', {
            slidesPerView: 'auto',
            spaceBetween: 10,
            speed: 3000,
            autoplay: {
              delay: 0,
              disableOnInteraction: false,
              reverseDirection: false,
            },
        });
    };

    function initSearchForm() {
        const formInitialTop = searchForm.offsetTop;
        let isFixed = false;
        let currentPlaceholder = null;
        
        function handleScroll() {
            let scrollY = window.scrollY;
            let navbarBottom = navbar.offsetHeight;
        
            if (scrollY + navbarBottom >= formInitialTop && !isFixed) {
                searchForm.classList.add("scrolling");
                isFixed = true;
            } else if (scrollY + navbarBottom < formInitialTop && isFixed) {
                searchForm.classList.remove("scrolling");
                isFixed = false;
            }
        
            let newPlaceholder = null;
            placeholders.forEach(placeholder => {
                let rect = placeholder.getBoundingClientRect();
                let formRect = searchForm.getBoundingClientRect();
        
                if (rect.top <= formRect.bottom && rect.bottom >= formRect.top) {
                    newPlaceholder = placeholder;
                }
            });
        
            if (newPlaceholder && newPlaceholder !== currentPlaceholder) {
                let tempText = searchInput.placeholder;
                searchInput.placeholder = newPlaceholder.textContent.trim();
                newPlaceholder.textContent = tempText;
        
                currentPlaceholder = newPlaceholder;
            }
        
            if (!newPlaceholder) {
                currentPlaceholder = null;
            }
        
            requestAnimationFrame(handleScroll);
        }
        
        requestAnimationFrame(handleScroll);
    }

    function initAnimations() {
        const icons = self.querySelectorAll(".travelers-love-section .card-img");
        const observer = new IntersectionObserver(
            (entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add("show");
                        observer.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.1 }
        );
    
        icons.forEach(icon => observer.observe(icon));
    }
};

new SelectorInitiator().setObject(new HomePage());
