'use strict';

const HostPopup = function () {
    this.selector = '.js-host-details-popup';
    let currentPopup;

    this.init = function () {
        initHosts();
        initToggleHiddenContent('.js-hidden-toggle-button', '.additional-information', 'Hidden', 'Discover more about me');
    };

    function initHosts() {
        document.querySelectorAll('.js-host-details-popup').forEach(popup => {
            initReviewsCard(popup);
            popup.querySelector('.js-popup-close-button').addEventListener('click', (event) => {
                event.preventDefault();
                closePopup();
            });

            const uploadMoreReviewsButton = popup.querySelector('.js-upload-more-host-reviews');
            if (uploadMoreReviewsButton) {
                uploadMoreReviewsButton.addEventListener('click', (event) => {
                    event.preventDefault();
                    uploadMoreHostReviews(uploadMoreReviewsButton);
                });
            }
        });

        function openPopup(slug) {
            currentPopup = document.querySelector(`.js-host-details-popup[data-slug="${slug}"]`);
            currentPopup.classList.remove('hidden-popup');
            scrollLock().lock();
        }

        function closePopup() {
            currentPopup.classList.add('hidden-popup');
            scrollLock().unlock();
        }

        document.addEventListener('click', (event) => {
            const cardHost = event.target.closest('.js-card-host-info');
            if (cardHost) {
                event.preventDefault();
                openPopup(cardHost.dataset.slug);
            }
        });
    }

    function uploadMoreHostReviews(uploadBtn) {
        const loadReviewLink = uploadBtn.dataset.loadreviewlink;
        const page = parseInt(uploadBtn.dataset.page, 10);
        const limit = parseInt(uploadBtn.dataset.limit, 10);
        const offset = page * limit;

        showLoader();
        fetch('/api/reviews?' + loadReviewLink + '&offset=' + offset, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            }
        })
            .then(response => {
                return response.json();
            })
            .then(data => {
                hideLoader();
                const reviewList = currentPopup.querySelector('.js-host-popup-reviews-list');
                data.forEach(item => {
                    const newReviewItem = currentPopup.querySelector('.js-host-review-item').cloneNode(true);
                    newReviewItem.querySelector('.js-review-date').textContent = item.date;
                    newReviewItem.querySelector('.js-review-by-name').textContent = item.name;
                    newReviewItem.querySelector('.js-review-text').textContent = item.review;
                    newReviewItem.dataset.reviewid = item.id;

                    const starsContainer = newReviewItem.querySelector('.js-review-stars');
                    starsContainer.innerHTML = '';
                    for (let i = 0; i < item.rating; i++) {
                        const starItem = Object.assign(document.createElement('li'), {className: 'list-item'});
                        const starIcon = Object.assign(document.createElement('i'), {className: 'icon-star'});

                        starItem.appendChild(starIcon);
                        starsContainer.appendChild(starItem);
                    }

                    reviewList.appendChild(newReviewItem);
                });
                uploadBtn.dataset.page = page + 1;
                if (data.length < limit) {
                    uploadBtn.classList.add('no-display');
                }
            })
            .catch(error => {
                hideLoader();
                console.error('Error:', error);
            });
    }
};

new SelectorInitiator().setObject(new HostPopup());