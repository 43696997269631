'use strict'

const WebPushPopup = function() {
    this.selector = '.push-popup-container';

    this.init = function() {
        let scrolled = false;

        const popupContainer = $('.push-popup-container');
        const delay = popupContainer.attr('data-delay');
        const isScroll = Boolean(popupContainer.attr('data-isscroll'));
        const webPushAllowed = Notification.permission === 'granted';

        if (!webPushAllowed) {
            if (isScroll === true) {
                $(window).on('scroll', function() {
                    setTimeout(() => {
                        if (!scrolled) {
                            scrolled = true;
                            popupContainer.removeAttr('hidden');
                        }
                    }, delay);
                });
            }
            else {
                setTimeout(() => {
                    popupContainer.removeAttr('hidden');
                }, delay);
            }
            
            $('.reject-button').on('click', function() {
                popupContainer.attr('hidden', 'hidden');
                const expires = new Date();
                expires.setMonth(expires.getMonth() + 1);
                document.cookie = `web_push_notification_rejected=true; expires=${expires.toUTCString()}; path=/ `;
            });
    
            $('.allow-button').on('click', function() {
                (new ServiceWorkerService()).init();
                popupContainer.attr('hidden', 'hidden');
            });
        }

    }
}

new SelectorInitiator().setObject(new WebPushPopup());